import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Accordion, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { lostBrandDescription, pyzelBrandDescription, firewireBrandDescription, channelIslandsBrandDescription, haydenshapesBrandDescription, jsIndustriesBrandDescription } from './brandDescription';


let YT;

const BrandDetails = () => {
    const { brandName } = useParams();
    const [brandDetails, setBrandDetails] = useState(null);
    const [player, setPlayer] = useState(null);
    const ytPlayerRef = useRef(null);
    const [YTReady, setYTReady] = useState(false);

    const brandUrlMapping = {
      'Lost': 'Lost',
      'JS Industries': 'js-industries',
      'Firewire': 'Firewire',
      'Channel Islands': 'Channel-island',
      'Haydenshapes': 'Haydenshape',
      'Pyzel': 'Pyzel'
  };
  

    

    const brandsData = [
        {
            brandName: 'Lost',
            officialPage: 'https://lostsurfboards.net/',
            brandDescription: lostBrandDescription,
            imageUrls: ['image_url1', 'image_url2'],
            mediaLinks: {
                officialPresentation: [{ url: 'https://youtube.com/watch?v=2dr-1fhODpM', description: 'Official video' }],
                review: [],
                battle: []
            },
            youtubeChannelId: 'UC_x5XGvYjqw6lIqE9l2v5mw'
        },
        {
            brandName: 'Pyzel',
            officialPage: 'https://pyzelsurfboards.com/',
            brandDescription: pyzelBrandDescription,
            imageUrls: ['image_url3', 'image_url4'],
            mediaLinks: {
                officialPresentation: [{ url: 'video_url2', description: 'Official video' }],
                review: [],
                battle: []
            }
        },
        {
            brandName: 'Firewire',
            officialPage: 'https://firewiresurfboards.com/',
            brandDescription: firewireBrandDescription,
            imageUrls: ['image_url5', 'image_url6'],
            mediaLinks: {
                officialPresentation: [{ url: 'https://youtube.com/watch?v=example', description: 'Official video' }],
                review: [],
                battle: []
            }
        },
        {
            brandName: 'Channel Islands',
            officialPage: 'https://cisurfboards.com/',
            brandDescription: channelIslandsBrandDescription,
            imageUrls: ['image_url7', 'image_url8'],
            mediaLinks: {
                officialPresentation: [{ url: 'https://youtube.com/watch?v=example', description: 'Official video' }],
                review: [],
                battle: []
            }
        },
        {
            brandName: 'Haydenshapes',
            officialPage: 'https://haydenshapes.com/',
            brandDescription: haydenshapesBrandDescription,
            imageUrls: ['image_url9', 'image_url10'],
            mediaLinks: {
                officialPresentation: [{ url: 'https://youtube.com/watch?v=example', description: 'Official video' }],
                review: [],
                battle: []
            }
        },
        {
            brandName: 'JS Industries',
            officialPage: 'https://jsindustries.com/',
            brandDescription: jsIndustriesBrandDescription,
            imageUrls: ['image_url11', 'image_url12'],
            mediaLinks: {
                officialPresentation: [{ url: 'https://youtube.com/watch?v=example', description: 'Official video' }],
                review: [],
                battle: []
            }
        },
        // Add more brands as needed
    ];
    
    useEffect(() => {
      const foundBrand = brandsData.find(brand => brandUrlMapping[brand.brandName] === brandName);
      if (foundBrand) {
          setBrandDetails(foundBrand);
      } else {
          console.log("Brand not found:", brandName);
      }
  }, [brandName]);

    useEffect(() => {
        if (typeof window.YT === 'undefined') {
            window.onYouTubeIframeAPIReady = () => {
                YT = window.YT;
                setYTReady(true);
            };

            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            document.body.appendChild(tag);
        } else {
            YT = window.YT;
            setYTReady(true);
        }

        return () => {
            window.onYouTubeIframeAPIReady = () => {};
        };
    }, []);

    useEffect(() => {
        if (!brandDetails || !brandDetails.youtubeChannelId || !YTReady) return;

        const newPlayer = new YT.Player(ytPlayerRef.current, {
            height: '490',
            width: '100%',
            videoId: brandDetails.mediaLinks.officialPresentation[0].url.split('v=')[1],
            playerVars: { 'playsinline': 1 },
            events: { 'onReady': onPlayerReady }
        });
        setPlayer(newPlayer);

        return () => {
            if (player) {
                player.destroy();
                setPlayer(null);
            }
        };
    }, [brandDetails, YTReady]);

    const onPlayerReady = (event) => {
        if (event.target) {
            console.log("Player is ready!");
        }
    };

    if (!brandDetails) {
        return (
            <main>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2>Loading...</h2>
                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }

    return (
        <main>
            <Container>
                <Helmet>
                    <title>The Ultimate Surfboards Comparator: {brandDetails.brandName}</title>
                    <meta name="description" content={brandDetails.brandDescription?.length > 160 ? brandDetails.brandDescription.substring(0, 160) + "..." : brandDetails.brandDescription} />
                </Helmet>

                <Row>
                    <Col xs={12} md={8}>
                        <h2>{brandDetails.brandName}</h2>

                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Description</Accordion.Header>
                                <Accordion.Body style={{ whiteSpace: 'pre-line' }}>                              
                              <div dangerouslySetInnerHTML={{ __html: brandDetails.brandDescription }} /> 
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        {brandDetails && brandDetails.youtubeChannelId && YTReady && (
                            <>
                                <h3>Official Videos</h3>
                                <div className="video-section">
                                    <div ref={ytPlayerRef} id="ytplayer"></div>
                                </div>
                            </>
                        )}
                    </Col>
                    <Col xs={12} md={4} style={{ marginTop: '20px' }}>
                        <MoreInfoCard details={brandDetails} />
                    </Col>
                </Row>
            </Container>
        </main>
    );
};

const MoreInfoCard = ({ details }) => (
    <Card>
        <Card.Header>More about the brand</Card.Header>
        <ListGroup variant="flush">
            <ListGroup.Item>Brand: {details.brandName}</ListGroup.Item>
            <ListGroup.Item>Official Website: <a href={details.officialPage} target="_blank" rel="noopener noreferrer">{details.brandName}</a></ListGroup.Item>
        </ListGroup>
    </Card>
);

export default BrandDetails;

