//testerdetail.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Accordion, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import testerDescriptions from './testerDescription'; // Import the descriptions


let YT;

const TesterDetails = () => {
    const { testerName } = useParams(); // Get tester name from URL
    const [testerDetails, setTesterDetails] = useState(null);
    const [player, setPlayer] = useState(null);
    const ytPlayerRef = useRef(null);
    const [YTReady, setYTReady] = useState(false);

    const testerSlugMap = {  // URL slug : Actual tester name
        'stab-in-the-dark': 'Stab in the Dark',
        'surf-n-show': 'Surf n Show',
        'the-boardshop': 'The Boardshop',
        'noa-deane': 'Noa Deane (former Stab in the Dark tester)', // Example
        // ... add more mappings
    };


    const testersData = [
        {
            testerName: 'Stab in the Dark',
            officialPage: 'https://stabmag.com/',
            youtubeChannelId: 'UCsG5dkqFUHZO6eY9uOzQqow',
        },
        {
            testerName: 'Surf n Show',
            officialPage: 'https://www.youtube.com/@SurfnsShow',
            youtubeChannelId: 'UCyG8LQXPDH7WPuobykGStcQ',
        },
        {
            testerName: 'The Boardshop',
            officialPage: 'https://www.boardshop.co.uk/',
            youtubeChannelId: 'UCnDg0M0gmC9Lx599w36JeoA',
        },
        {
            testerName: 'Noa Deane (former Stab in the Dark tester)',
            officialPage: 'https://www.instagram.com/noadeane/',
            youtubeChannelId: 'UC_x5XGvYjqw6lIqE9l2v5mw',
        },
        // ... more testers
    ];

    useEffect(() => {
        const actualTesterName = testerSlugMap[testerName];

        if (actualTesterName) {
            const foundTester = testersData.find(tester => tester.testerName === actualTesterName);
            if (foundTester) {
                setTesterDetails({
                    ...foundTester,
                    description: testerDescriptions[actualTesterName] // Add description to tester details
                });
            } else {
                console.log("Tester data not found for:", actualTesterName);
            }
        } else {
            console.log("Tester slug not found:", testerName);
        }
    }, [testerName]);

    useEffect(() => {
        if (!testerDetails || !testerDetails.youtubeChannelId || !YTReady || player) return; // Added player check

        const fetchPlaylistId = async () => {
            try {
                const response = await fetch(
                    `https://youtube.googleapis.com/youtube/v3/channels?part=contentDetails&id=${testerDetails.youtubeChannelId}&key=YOUR_API_KEY` // Replace with your API key
                );
                const data = await response.json();

                if (data.items && data.items.length > 0) {
                    const uploadsPlaylistId = data.items[0].contentDetails.relatedPlaylists.uploads;

                    const newPlayer = new YT.Player(ytPlayerRef.current, {
                        height: '490',
                        width: '100%',
                        playerVars: { 'playsinline': 1 },
                        // Now use loadPlaylist instead of playlistId
                        events: {
                            'onReady': onPlayerReady,
                            'onError': onPlayerError, // Handle errors
                            'onStateChange': onPlayerStateChange,
                        },
                    });

                    newPlayer.loadPlaylist({
                        playlistId: uploadsPlaylistId,
                        index: 0, // Start from the first video
                        suggestedQuality: 'hd1080', // Optional: Set preferred quality
                    });


                    setPlayer(newPlayer);
                } else {
                    console.error("Could not find uploads playlist ID.");
                }
            } catch (error) {
                console.error("Error fetching playlist ID:", error);
            }
        };

        fetchPlaylistId();

        return () => {
            if (player) {
                player.destroy();
                setPlayer(null);
            }
        };
    }, [testerDetails, YTReady, player]); // Added player to dependencies



    useEffect(() => {
        if (typeof window.YT === 'undefined') {
            window.onYouTubeIframeAPIReady = () => {
                YT = window.YT;
                setYTReady(true);
            };

            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api"; // Corrected URL
            document.body.appendChild(tag);
        } else {
            YT = window.YT;
            setYTReady(true);
        }

        return () => {
            window.onYouTubeIframeAPIReady = () => {};
        };
    }, []);

    const onPlayerReady = (event) => {
        console.log("Player is ready!");
    };

    const onPlayerError = (event) => {
        console.error("YouTube Player Error:", event);
    };

    const onPlayerStateChange = (event) => {
        // Log player state changes (optional, for debugging)
        console.log("Player State Changed:", event.data);
    };


    if (!testerDetails) {
        return (
            <main>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2>Loading...</h2>
                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }

    return (
        <main>
            <Container>
                <Helmet>
                    <title>Surfboard Tester: {testerDetails.testerName}</title>
                    <meta name="description" content={testerDetails.description?.length > 160 ? testerDetails.description.substring(0, 160) + "..." : testerDetails.description} />
                </Helmet>

                <Row>
                    <Col xs={12} md={8}>
                        <h2>{testerDetails.testerName}</h2>

                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Description</Accordion.Header>
                                <Accordion.Body style={{ whiteSpace: 'pre-line' }}>
                                    <div dangerouslySetInnerHTML={{ __html: testerDetails.description }} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        {testerDetails && testerDetails.youtubeChannelId && YTReady && (
                            <>
                                <h3>Official Videos</h3>
                                <div className="video-section">
                                    <div ref={ytPlayerRef} id="ytplayer"></div>
                                </div>
                            </>
                        )}
                    </Col>
                    <Col xs={12} md={4} style={{ marginTop: '20px' }}>
                        <TesterInfoCard details={testerDetails} />
                    </Col>
                </Row>
            </Container>
        </main>
    );
};

const TesterInfoCard = ({ details }) => (
    <Card>
        <Card.Header>More about the Tester</Card.Header>
        <ListGroup variant="flush">
            <ListGroup.Item>Tester: {details.testerName}</ListGroup.Item>
            <ListGroup.Item>Official Website: <a href={details.officialPage} target="_blank" rel="noopener noreferrer">{details.officialPage}</a></ListGroup.Item>
        </ListGroup>
    </Card>
);

export default TesterDetails;