// routes/AppRoutes.js

import React from 'react';
import { Routes, Route } from 'react-router-dom'; // No need for BrowserRouter here

import SearchPage from '../components/SearchPage';
import SurfboardDetailsPage from '../components/SurfboardDetails';
import GuidedSearch from '../components/GuidedSearch';
import ComparePage from '../components/ComparePage';
import BrandDetailsPage from '../components/BrandDetails';
import TesterDetails from '../components/testerDetail.js'; // Adjust path as needed

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/compare" element={<ComparePage />} />
            <Route path="/search" element={<SearchPage />} /> {/* ONLY this route for search */}
            <Route path="/surfboard/:surfboardId" element={<SurfboardDetailsPage />} />
            <Route path="/GuidedSearch" element={<GuidedSearch />} />
            <Route path="/brand/:brandName" element={<BrandDetailsPage />} />
            <Route path="/tester/:testerName" element={<TesterDetails />} />
            {/* Add more routes as needed */}
        </Routes>
    );
};

export default AppRoutes;