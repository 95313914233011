//surfboardTester.js

import React, { useState } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SurfboardTesterMenu = () => {
    const [showMoreTesters, setShowMoreTesters] = useState(false);

    const initialTesters = [
        { name: 'Stab in the Dark', link: '/tester/stab-in-the-dark' },
        { name: 'Surf\'n Show', link: '/tester/surf-n-show' },
        { name: 'The Boardshop', link: '/tester/the-boardshop' },        
    ];

    const additionalTesters = [  // Add more testers here
        { name: 'Compare Surfboards', link: '/tester/compare-surfboards' },
        { name: 'Surf Simply Magazine', link: '/tester/surf-simply-magazine' },
        { name: 'Surfer Rule', link: '/tester/surfer-rule' },
        { name: 'Global Surf Industries', link: '/tester/global-surf-industries' },
        { name: 'Surf Research', link: '/tester/surf-research' },
        { name: 'Surfboard Guide', link: '/tester/surfboard-guide'},
        { name: 'WavePool', link: '/tester/wave-pool'},
        { name: 'Real Watersports', link: '/tester/real-watersports'},
        { name: 'Green Room Surfboards', link: '/tester/green-room-surfboards'},
        { name: 'Board Engine', link: '/tester/board-engine'}
    ];

    //const testersToShow = showMoreTesters ? [...initialTesters, ...additionalTesters] : initialTesters;
    const testersToShow = initialTesters;

    return (
        <Card>
            <Card.Header as="h5" className="text-center">
                Testers & Reviews
            </Card.Header>
            <ListGroup variant="flush">
                {testersToShow.map((tester) => (
                    <ListGroup.Item key={tester.name}>
                        <Link to={tester.link} className='menuFirstItem'>
                            {tester.name}
                        </Link>
                    </ListGroup.Item>
                ))}
                
            </ListGroup>
        </Card>
    );
};

export default SurfboardTesterMenu;