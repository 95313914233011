//This is the APp.js file
import "./globals.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumbs from './components/Breadcrumbs.js'; // Ensure you have this component set up
import Footer from './components/Footer.js';  // Adjust the path as necessary
import 'bootstrap/dist/css/bootstrap.min.css';
import { CompareSurfboardsProvider } from './contexts/CompareSurfboardsContext'
import { useCompareSurfboards } from './contexts/CompareSurfboardsContext';  // Adjust the path as necessary
import SurfboardTesterMenu from './components/surfboardTester.js';  // Adjust the path as necessary
import TesterDetails from './components/testerDetail.js'; // Adjust path

import AppRoutes from './routes/AppRoutes'; // Import the routes


import React, { useState, useLayoutEffect, Suspense } from 'react';
import { Link, BrowserRouter as Router, usePathname, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { Helmet } from 'react-helmet';

//const inter = Inter({ subsets: ["latin"] });
const toolsItems = [
  { key: '1', title: 'Compare surfboards', href: 'compare' }
];

// Assuming wave size is measured in feet and we're interested in waves from 1 to 12 feet
const waveSizeRange = {
  min: 1,
  max: 12,
}; const surfboardMenuItemsComplete = [
  { key: 'all', title: 'All Surfboards', href: '/search/all' },
  { key: 'brand', title: 'By Brand', href: '/search/brand' },
  { key: 'waveSize', title: 'By Wave Size', href: '/search/wave-size' },
  { key: 'type', title: 'By Surfer Ability', href: '/search/surfer-ability' },
  { key: 'type', title: 'By Surfboard Type', href: '/search/surfboard-type' },
  { key: 'waveType', title: 'By Wave Type', href: '/search/wave-type' },
];

const surfboardMenuItems = [
  { key: 'brand', title: 'By Brand', href: '/search?brand=' }, // Add ?brand=
  { key: 'waveSize', title: 'By Wave Size', href: '/search?size=' }, // Add ?size=
];

const brandMenuItems = [
  { key: 'js-industries', title: 'JS Industries', href: '/search?brand=JS-Industries', imageUrl: '/img/JS.webp' }, // Correct href
  { key: 'firewire', title: 'Firewire', href: '/search?brand=Firewire', imageUrl: '/img/firewire.png' }, // Correct href
  { key: 'channel-island', title: 'Channel Island', href: '/search?brand=Channel-Island', imageUrl: '/img/channel-islands.png' }, // Correct href
  { key: 'Haydenshape', title: 'Haydenshape', href: '/search?brand=Haydenshape', imageUrl: '/img/haydenshapes-logo.svg' }, // Correct href
  { key: 'Lost', title: 'Lost', href: '/search?brand=Lost', imageUrl: '/img/lost_lockup_2025.svg' }, // Correct href
  { key: 'Pyzel', title: 'Pyzel', href: '/search?brand=Pyzel', imageUrl: '/img/Pyzel.png' }, // Correct href
  // Add more brands as needed
];

const sizeMenuItems = [
  { key: 'knee', title: 'Knee (0 to 2ft)', href: '/search?size=knee' }, // Correct href
  { key: 'waist', title: 'Waist (2 to 4ft)', href: '/search?size=waist' }, // Correct href
  { key: 'shoulder', title: 'Shoulder (4 to 6ft)', href: '/search?size=shoulder' }, // Correct href
  { key: 'overhead+', title: 'Overhead+ (6ft+)', href: '/search?size=overhead' }, // Correct href
  // Add more sizes as needed
];

const brandDetailItems = [
  { key: 'js-industries', title: 'JS Industries', href: '/brand/JS-Industries', imageUrl: '/img/JS.webp' }, // Correct href
  { key: 'firewire', title: 'Firewire', href: '/brand/Firewire', imageUrl: '/img/firewire.png' }, // Correct href
  { key: 'channel-island', title: 'Channel Island', href: '/brand/Channel-Island', imageUrl: '/img/channel-islands.png' }, // Correct href
  { key: 'Haydenshape', title: 'Haydenshape', href: '/brand/Haydenshape', imageUrl: '/img/haydenshapes-logo.svg' }, // Correct href
  { key: 'Lost', title: 'Lost', href: '/brand/Lost', imageUrl: '/img/lost_lockup_2025.svg' }, // Correct href
  { key: 'Pyzel', title: 'Pyzel', href: '/brand/Pyzel', imageUrl: '/img/Pyzel.png' }, // Correct href
  // Add more sizes as needed
];

const SizeSubMenu = ({ items }) => {
  const navigate = useNavigate();

  return (
    <ListGroup variant="flush">
      {items.map((item) => (
        <ListGroup.Item
          action
          key={item.key}
          className="list-group-item-custom"
        >
          <Link to={item.href} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', width: '100%' }}> {/* Link wraps everything */}
            {item.title}
          </Link>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

const BrandSubMenu = ({ items }) => {
  const navigate = useNavigate();

  return (
    <ListGroup variant="flush">
      {items.map((item) => (
        <ListGroup.Item
          action
          key={item.key}
          className="list-group-item"
        >
          <Link to={item.href} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', width: '100%' }}> {/* Link wraps everything */}
            {item.imageUrl && <img src={item.imageUrl} alt={item.title} style={{ width: 'auto', height: '20px', marginRight: '10px' }} />}
            {item.title}
          </Link>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

const SurfboardToolsMenu = () => {
  const { comparedSurfboards } = useCompareSurfboards();
  console.log(comparedSurfboards);

  return (
    <ToolsMenu items={toolsItems} surfboards={comparedSurfboards} />
  );
}

const DynamicMenu = ({ items, brandItems, sizeItems, }) => {
  const location = useLocation();
  const [showSizeMenu, setShowSizeMenu] = useState(false);
  const [showBrandMenu, setShowBrandMenu] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  // No need for determineInitialVisibility anymore

  return (
    <div className="side-menu" style={{ maxWidth: '300px' }}>
      <Helmet>  {/* Helmet is back! */}
        <title>Surfboard Buying Guide: Find Your Perfect Board</title>
        <meta name="keywords" content="surfboard buying guide, surfboard comparison, best surfboards, beginner surfboards, longboards, shortboards, surfboard reviews, surfboard sizes, surfboard shapes, choosing a surfboard" />
        <meta name="description" content="Find the perfect surfboard for your skill level and surfing style. Our comprehensive buying guide covers everything from surfboard types (longboards, shortboards, etc.) and sizes to essential considerations for beginners and experienced surfers. Read reviews, compare options, and make an informed decision." />
      </Helmet>
      <Card>
        <Card.Header as="h5" className="text-center">
          The Guide for your perfect surfboard  {/* This is the restored section */}
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Link to="/GuidedSearch" className='menuFirstItem'>The Search Guide</Link> {/* This is the restored section */}
          </ListGroup.Item>
        </ListGroup>
      </Card> {/* Close the Card for the Guide section */}
      <br/>
      <Card> {/* Start a new Card for Surfboards */}
        <Card.Header as="h5" className="text-center">
          Surfboards
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item >
            <Link to="/search?category=all" className='menuFirstItem'> All Surfboards </Link>
          </ListGroup.Item>
          {items.map((item) => {
            if (item.key === 'brand') {
              return (
                <React.Fragment key={item.key}>
                  <ListGroup.Item action onClick={() => setShowBrandMenu(!showBrandMenu)} style={{ cursor: 'pointer' }}>
                    {item.title}
                  </ListGroup.Item>
                  {showBrandMenu && <BrandSubMenu items={brandItems} />}
                </React.Fragment>
              );
            } else if (item.key === 'waveSize') {
              return (
                <React.Fragment key={item.key}>
                  <ListGroup.Item action onClick={() => setShowSizeMenu(!showSizeMenu)} style={{ cursor: 'pointer' }}>
                    {item.title}
                  </ListGroup.Item>
                  {showSizeMenu && <SizeSubMenu items={sizeItems} />}
                </React.Fragment>
              );
            } else {
              return (
                <ListGroup.Item action href={`/${item.href}`} key={item.key}> {/* Keep this for other items */}
                  {item.title}
                </ListGroup.Item>
              );
            }
          })}
        </ListGroup>
      </Card>
    </div>
  );
};

const DynamicMenuWrapper = () => {
  return (
    <Suspense fallback={<div>Loading menu...</div>}>
      <DynamicMenu
        items={surfboardMenuItems}
        brandItems={brandMenuItems}
        sizeItems={sizeMenuItems}
      />
    </Suspense>
  );
};



const breadcrumbItems = [
  { title: 'Home', href: '/' },
  // Add more breadcrumb items as needed
];

const ToolsMenu = ({ items, surfboards }) => {
  const { removeSurfboardFromCompare } = useCompareSurfboards();  

  return (
    <div className="side-menu" style={{ maxWidth: '300px', marginTop: '20px' }}>
      <Card>
        <Card.Header as="h5" className="text-center">
          The Comparator
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <span className="nothing"> Selected board</span>
          </ListGroup.Item>
          {surfboards && surfboards.map(surfboard => (
            <ListGroup.Item action as="div" key={surfboard.id}>
              <div className="d-flex justify-content-between align-items-center">
                {surfboard.surfboardName}
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => removeSurfboardFromCompare(surfboard.id)}
                >
                  Remove
                </button>
              </div>
            </ListGroup.Item>
          ))}
          {Array.isArray(surfboards) && surfboards.length > 1 && (
            <ListGroup.Item>
              <Link to="/compare">
                <button className="btn btn-success btn-sm">
                  Compare
                </button>
              </Link>
            </ListGroup.Item>
          )}
          {items.filter(item => item.key !== '1').map((item) => (
            <ListGroup.Item action href={item.href} key={item.key}>
              {item.title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card>
      <br/>

    </div>
  );
};

const SurfboardBrandMenu = ({ brandDetailItems, showBrandDetailsMenu, setShowBrandDetailsMenu, brandMenuItems }) => { // Correct props
  return (
      <Card>
          <Card.Header as="h5" className="text-center">
          Everything about brands
          </Card.Header>
          <ListGroup variant="flush">
              <ListGroup.Item>
                  <Link to="/brand/Lost" className='menuFirstItem'> Lost surfboards </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                  <Link to="/brand/js-industries" className='menuFirstItem'> JS Industries </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                  <Link to="/brand/Firewire" className='menuFirstItem'> Firewire </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                  <Link to="/brand/Channel-island" className='menuFirstItem'> Channel Island </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                  <Link to="/brand/Haydenshape" className='menuFirstItem'> Haydenshape </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                  <Link to="/brand/Pyzel" className='menuFirstItem'> Pyzel </Link>
              </ListGroup.Item>
              {brandDetailItems.map((item) => {
                  if (item.key === 'brand') {
                      return (
                          <React.Fragment key={item.key}>
                              <ListGroup.Item
                                  action
                                  onClick={() => setShowBrandDetailsMenu(!showBrandDetailsMenu)} // Use passed-down setter
                                  style={{ cursor: 'pointer' }}
                              >
                                  {item.title}
                              </ListGroup.Item>
                              {showBrandDetailsMenu && <BrandSubMenu items={brandMenuItems} />} {/* Use passed-down state and brandMenuItems */}
                          </React.Fragment>
                      );
                  }
              })}
          </ListGroup>
      </Card>
  );
};




function AppContent() {
  const location = useLocation();
  const [showBrandDetailsMenu, setShowBrandDetailsMenu] = useState(false); // State for Brand Details HERE


  return (
    <Container fluid>
      <Row className="align-items-center">
        
        <Col xs={1}>
          <a href="/">
            <img id="logo" src="\img\logo.png" alt="logo of a surfboard with a tick" />
          </a>
        </Col>
        <Col xs={9}>
          <h1 className="title">The Ultimate Surfboards Comparator</h1>
        </Col>
        <Col xs={2}>
          <a href="/admin">Admin</a>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Breadcrumbs items={breadcrumbItems} />
        </Col>
      </Row>

      <Container fluid className="text-black bg-white">
        <Row>
          <Col xs={12} md={2}>
            <DynamicMenuWrapper />
            <SurfboardToolsMenu />
            <SurfboardBrandMenu  // Render SurfboardBrandMenu here
                        brandDetailItems={brandDetailItems}
                        showBrandDetailsMenu={showBrandDetailsMenu} // Pass state!
                        setShowBrandDetailsMenu={setShowBrandDetailsMenu} // Pass setter!
                        brandMenuItems={brandMenuItems} // Pass brandMenuItems as well
                    />
                    <br/>
                    <SurfboardTesterMenu />

          </Col>          
          
          <Col xs={12} md={10} className="d-flex align-items-center justify-content-center">
            {location.pathname === '/' && (
              <div className="intro-container text-center vh-100 d-flex flex-column justify-content-center align-items-center">
                <div className="top-section mb-3">
                <h1>Finding the perfect surfboard just got easier.<br/> <br/> Browse hundreds of boards, compare key specs side-by-side, watch video reviews, and get expert recommendations.<br/><br/>
                <span><b>Sticks.surf</b> simplifies your search for the ideal ride.</span></h1>
                  <div className="custom-image-container d-flex justify-content-center align-items-center">
                    <img src="/img/logo.png" alt="Your perfect wave companion" width={700} height={700} />
                  </div>
                </div>
                <div className="text-and-button-container mt-3">
                  <p className="intro-text mb-2">
                    Find your perfect match with our guide! We help you choose the ideal surfboard from
                    hundreds of options, considering your experience level, surfing style,
                    and wave conditions. Explore reviews, compare features, and make an
                    informed decision.
                  </p>
                  <button onClick={() => window.location.href = '/GuidedSearch'}>Magic Board finder</button>
                </div>
              </div>
            )}
            <AppRoutes />
          </Col>
        </Row>
      </Container>

      <Footer />
    </Container>
  );
}

function App() {
  return (
    <CompareSurfboardsProvider>
      <Router>
        <AppContent />
      </Router>
    </CompareSurfboardsProvider>
  );
}

export default App;