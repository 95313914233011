// brandDescriptions.js

export const lostBrandDescription = `
<p>Lost Surfboards embodies the rebellious spirit of surfing. Born from the creative energy of the 1980s surf and skate scene, Lost has become synonymous with innovation, performance, and a distinctive aesthetic. They cater to a wide range of surfers, from beginners to pros, offering boards designed for everything from small beach breaks to pumping point breaks. Lost is known for its cutting-edge shapes, construction techniques, and a commitment to quality craftsmanship. They're not just building surfboards; they're building tools for surfers to express their style and push their limits.</p>

<p><b>Key Details:</b></p>
<ul>
    <li><b>Founder/Shaper:</b> Matt "Mayhem" Biolos is the driving force behind Lost. His shaping philosophy blends performance with a touch of the unconventional, resulting in boards that are both functional and visually striking. While Mayhem is the primary shaper, Lost also collaborates with other shapers and utilizes advanced shaping technology.</li>
    <li><b>Team Riders (Past and Present):</b> Lost has a history of supporting some of the most stylish and progressive surfers. While team rosters evolve, Lost has been associated with surfers who embody the brand's unique blend of performance and style.</li>
    <li><b>Popular Surfboards:</b> Lost offers a diverse range of boards. Some consistently popular models include:
        <ul>
            <li><b>The Driver:</b> A high-performance shortboard known for its versatility in a variety of conditions. Often a go-to for advanced surfers.</li>
            <li><b>The Puddle Jumper:</b> A popular groveler designed for smaller, weaker waves. Provides a lot of fun in less-than-ideal conditions.</li>
            <li><b>The RV:</b> Another popular shortboard, often praised for its balance of speed and control.</li>
            <li><b>The Rocket:</b> A performance board designed for good waves.</li>
        </ul>
    </li>
    <li><b>Construction:</b> Lost utilizes various construction methods, including traditional PU/polyester, epoxy, and other advanced materials. They often incorporate innovative technologies to enhance performance and durability.</li>
</ul>

<p><b>Additional Points to Consider:</b></p>
<ul>
    <li><b>"...Lost" Branding:</b> The "...Lost" prefix in their name is a nod to their early days and the often-lost nature of surfboards. It's become part of their brand identity.</li>
    <li><b>Connection to Surf Culture:</b> Lost is deeply connected to the Southern California surf and skate scene. Their designs often reflect the trends and styles of this influential community.</li>
    <li><b>Evolution:</b> Lost has evolved over the years, adapting to changes in surfboard design and surfing styles. They continue to innovate and push the boundaries of what's possible on a surfboard.</li>
</ul>

<p><b>Where to Find More Information:</b></p>
<ul>
    <li><a href="https://lostsurfboards.net/" target="_blank" rel="noopener noreferrer">Lost Surfboards Official Website</a></li>
    <li>Surfboard Reviews: Websites and magazines that review surfboards often have information on Lost boards.</li>
</ul>
`;

export const pyzelBrandDescription = `
<p>Pyzel Surfboards is known for its combination of craftsmanship and innovation. Created by Jon Pyzel, the brand has built a reputation for high-performance surfboards that excel in a wide range of conditions. Pyzel boards are popular among both amateur and professional surfers, with a focus on precision shaping and cutting-edge design.</p>

<p><b>Key Details:</b></p>
<ul>
    <li><b>Founder/Shaper:</b> Jon Pyzel is the master shaper behind Pyzel Surfboards. With years of experience and a deep understanding of hydrodynamics, Jon's designs are both functional and visually striking. He continuously collaborates with top surfers to refine and enhance his shapes.</li>
    <li><b>Team Riders (Past and Present):</b> Pyzel has supported some of the best surfers in the world, including John John Florence, who has won multiple world championships riding Pyzel boards. The team is known for pushing the limits of performance and style.</li>
    <li><b>Popular Surfboards:</b> Pyzel offers a diverse range of boards. Some consistently popular models include:
        <ul>
            <li><b>The Ghost:</b> A versatile shortboard that performs well in various conditions. It's known for its balance of speed, control, and maneuverability.</li>
            <li><b>The Phantom:</b> A user-friendly board designed for intermediate to advanced surfers. It provides a smooth ride in smaller to medium waves.</li>
            <li><b>The Gremlin:</b> A high-performance board that excels in powerful waves. It's designed for advanced surfers looking for speed and control.</li>
            <li><b>The Shadow:</b> A performance shortboard that is a favorite among competitive surfers. It's praised for its responsiveness and drive.</li>
        </ul>
    </li>
    <li><b>Construction:</b> Pyzel Surfboards utilizes various construction methods, including traditional PU/polyester, epoxy, and other advanced materials. They often incorporate innovative technologies to enhance performance and durability.</li>
</ul>

<p><b>Additional Points to Consider:</b></p>
<ul>
    <li><b>Pyzel Branding:</b> The brand is known for its clean and professional aesthetic, reflecting the precision and quality of their boards.</li>
    <li><b>Connection to Surf Culture:</b> Pyzel Surfboards is deeply rooted in the surfing communities of Hawaii and beyond. Their designs often reflect the demands and styles of top-tier surfers.</li>
    <li><b>Evolution:</b> Pyzel has continuously evolved, adapting to changes in surfboard design and surfing styles. They remain at the forefront of surfboard innovation and performance.</li>
</ul>

<p><b>Where to Find More Information:</b></p>
<ul>
    <li><a href="https://pyzelsurfboards.com/" target="_blank" rel="noopener noreferrer">Pyzel Surfboards Official Website</a></li>
    <li>Surfboard Reviews: Websites and magazines that review surfboards often have information on Pyzel boards.</li>
</ul>
`;

// brandDescriptions.js

export const haydenshapesBrandDescription = `
<p>Haydenshapes Surfboards is a brand recognized for its innovative approach to surfboard design and technology. Founded by Hayden Cox, the brand has gained a reputation for producing high-performance boards that push the boundaries of traditional surfboard construction.</p>

<p><b>Key Details:</b></p>
<ul>
    <li><b>Founder/Shaper:</b> Hayden Cox is the visionary shaper behind Haydenshapes. His focus on innovation has led to the development of groundbreaking designs and materials, such as the patented FutureFlex technology, which enhances performance and durability.</li>
    <li><b>Team Riders (Past and Present):</b> Haydenshapes has supported a number of talented surfers, including the likes of Craig Anderson. The team is known for its progressive approach to surfing and its endorsement of the brand's cutting-edge designs. (Note: It's always best to check Haydenshapes' official website for the most up-to-date team information).</li>
    <li><b>Popular Surfboards:</b> Haydenshapes offers a diverse range of boards. Some consistently popular models include:
        <ul>
            <li><b>Hypto Krypto:</b> A versatile all-rounder that performs well in various conditions. It's known for its balance of speed, control, and maneuverability.</li>
            <li><b>Love Buzz:</b> A user-friendly board designed for intermediate to advanced surfers. It provides a smooth ride in smaller to medium waves.</li>
            <li><b>Plunder:</b> A high-performance board that excels in powerful waves. It's designed for advanced surfers looking for speed and control.</li>
            <li><b>White Noiz:</b> A performance shortboard that is a favorite among competitive surfers. It's praised for its responsiveness and drive.</li>
        </ul>
    </li>
    <li><b>Construction:</b> Haydenshapes Surfboards utilizes various construction methods, including traditional PU/polyester, epoxy, and other advanced materials. Their innovative FutureFlex technology is designed to enhance performance and durability.</li>
</ul>

<p><b>Additional Points to Consider:</b></p>
<ul>
    <li><b>Haydenshapes Branding:</b> The brand is known for its modern and minimalist aesthetic, reflecting the precision and quality of their boards.</li>
    <li><b>Connection to Surf Culture:</b> Haydenshapes is deeply connected to the global surf community, and their designs often reflect the feedback and demands of top surfers.</li>
    <li><b>Evolution:</b> Haydenshapes has continuously evolved, adapting to changes in surfboard design and surfing styles. They remain at the forefront of surfboard innovation and performance.</li>
</ul>

<p><b>Where to Find More Information:</b></p>
<ul>
    <li><a href="https://haydenshapes.com/" target="_blank" rel="noopener noreferrer">Haydenshapes Surfboards Official Website</a></li>
    <li>Surfboard Reviews: Websites and magazines that review surfboards often have information on Haydenshapes boards.</li>
</ul>
`;

export const firewireBrandDescription = `
<p>Firewire Surfboards is a brand known for its cutting-edge technology and sustainability in surfboard manufacturing. They have revolutionized the industry with their focus on environmentally friendly materials and construction methods. Firewire boards are designed to enhance performance while minimizing the ecological footprint, making them a favorite among eco-conscious surfers.</p>

<p><b>Key Details:</b></p>
<ul>
    <li><b>Founder/Shaper:</b> Firewire was founded by Nev Hyman, a visionary shaper who sought to innovate surfboard design and construction. The company has since collaborated with other renowned shapers like Kelly Slater and Daniel "Tomo" Thomson to bring unique designs to the market.</li>
    <li><b>Team Riders (Past and Present):</b> Firewire has a team of accomplished surfers, including the legendary Kelly Slater. Their team riders are known for pushing the boundaries of performance surfing and endorsing the brand's commitment to sustainability.</li>
    <li><b>Popular Surfboards:</b> Firewire offers a diverse range of boards. Some consistently popular models include:
        <ul>
            <li><b>The Sci-Fi:</b> Designed by Tomo, this board combines futuristic design with high performance. It's known for its speed, agility, and versatility in various conditions.</li>
            <li><b>The Dominator:</b> A user-friendly board that performs well in a wide range of waves. It's known for its stability and ease of use, making it a favorite among intermediate surfers.</li>
            <li><b>The Seaside:</b> Designed by Rob Machado, this board is perfect for small to medium waves. It's praised for its smooth ride and responsiveness.</li>
            <li><b>The Cymatic:</b> Another Tomo design, this board is known for its compact shape and high performance. It's perfect for advanced surfers looking for speed and control in powerful waves.</li>
        </ul>
    </li>
    <li><b>Construction:</b> Firewire is known for its innovative construction methods, including the use of eco-friendly materials like recycled EPS foam and bio-resins. They also utilize advanced technologies like Helium, TimberTek, and Linear Flex to enhance performance and durability.</li>
</ul>

<p><b>Additional Points to Consider:</b></p>
<ul>
    <li><b>Firewire Branding:</b> The brand is known for its commitment to sustainability and innovation, reflected in its clean and modern designs.</li>
    <li><b>Connection to Surf Culture:</b> Firewire is deeply connected to the global surf community, and their designs often reflect the feedback and demands of top surfers.</li>
    <li><b>Evolution:</b> Firewire has continuously evolved, adapting to changes in surfboard design and surfing styles. They remain at the forefront of surfboard innovation and sustainability.</li>
</ul>

<p><b>Where to Find More Information:</b></p>
<ul>
    <li><a href="https://firewiresurfboards.com/" target="_blank" rel="noopener noreferrer">Firewire Surfboards Official Website</a></li>
    <li>Surfboard Reviews: Websites and magazines that review surfboards often have information on Firewire boards.</li>
</ul>
`;

export const jsIndustriesBrandDescription = `
<p>JS Industries is a renowned surfboard brand known for its high-performance designs and innovative technology. Founded by Jason Stevenson, the brand has become a staple in the surfing community, producing boards that cater to surfers of all levels, from beginners to world champions.</p>

<p><b>Key Details:</b></p>
<ul>
    <li><b>Founder/Shaper:</b> Jason Stevenson is the master shaper behind JS Industries. His designs have set the standard for performance surfboards, and the brand continues to innovate under his guidance.</li>
    <li><b>Team Riders (Past and Present):</b> JS Industries has supported some of the best surfers in the world, including four World Champions and many top 32 contenders. The team is known for pushing the limits of performance surfing and endorsing the brand's commitment to quality and innovation.</li>
    <li><b>Popular Surfboards:</b> JS Industries offers a diverse range of boards. Some consistently popular models include:
        <ul>
            <li><b>The Monsta Box:</b> A versatile shortboard that performs well in various conditions. It's known for its balance of speed, control, and maneuverability.</li>
            <li><b>The Blak Box II:</b> A user-friendly board designed for intermediate to advanced surfers. It provides a smooth ride in smaller to medium waves.</li>
            <li><b>The Xero:</b> A high-performance board that excels in powerful waves. It's designed for advanced surfers looking for speed and control.</li>
            <li><b>The Big Baron:</b> A performance shortboard that is a favorite among competitive surfers. It's praised for its responsiveness and drive.</li>
        </ul>
    </li>
    <li><b>Construction:</b> JS Industries utilizes various construction methods, including traditional PU/polyester, epoxy, and other advanced materials. They often incorporate innovative technologies to enhance performance and durability.</li>
</ul>

<p><b>Additional Points to Consider:</b></p>
<ul>
    <li><b>JS Industries Branding:</b> The brand is known for its commitment to quality and innovation, reflected in its clean and professional designs.</li>
    <li><b>Connection to Surf Culture:</b> JS Industries is deeply connected to the global surf community, and their designs often reflect the feedback and demands of top surfers.</li>
    <li><b>Evolution:</b> JS Industries has continuously evolved, adapting to changes in surfboard design and surfing styles. They remain at the forefront of surfboard innovation and performance.</li>
</ul>

<p><b>Where to Find More Information:</b></p>
<ul>
    <li><a href="https://jsindustries.com/" target="_blank" rel="noopener noreferrer">JS Industries Official Website</a></li>
    <li>Surfboard Reviews: Websites and magazines that review surfboards often have information on JS Industries boards.</li>
</ul>
`;

export const channelIslandsBrandDescription = `
<p>Channel Islands Surfboards is a renowned brand known for its high-performance surfboards and innovative designs. Founded by Al Merrick, the brand has become a staple in the surfing community, producing boards that cater to surfers of all levels, from beginners to world champions.</p>

<p><b>Key Details:</b></p>
<ul>
    <li><b>Founder/Shaper:</b> Al Merrick is the legendary shaper behind Channel Islands Surfboards. His designs have set the standard for performance surfboards, and the brand continues to innovate under the guidance of his son, Britt Merrick.</li>
    <li><b>Team Riders (Past and Present):</b> Channel Islands has supported some of the best surfers in the world, including Tom Curren, Kelly Slater, and Dane Reynolds. The team is known for pushing the limits of performance surfing and endorsing the brand's commitment to quality and innovation.</li>
    <li><b>Popular Surfboards:</b> Channel Islands offers a diverse range of boards. Some consistently popular models include:
        <ul>
            <li><b>The Happy:</b> A versatile shortboard that performs well in various conditions. It's known for its balance of speed, control, and maneuverability.</li>
            <li><b>The Rocket Wide:</b> A user-friendly board designed for intermediate to advanced surfers. It provides a smooth ride in smaller to medium waves.</li>
            <li><b>The Fever:</b> A high-performance board that excels in powerful waves. It's designed for advanced surfers looking for speed and control.</li>
            <li><b>The Neckbeard 2:</b> A performance shortboard that is a favorite among competitive surfers. It's praised for its responsiveness and drive.</li>
        </ul>
    </li>
    <li><b>Construction:</b> Channel Islands Surfboards utilizes various construction methods, including traditional PU/polyester, epoxy, and other advanced materials. They often incorporate innovative technologies to enhance performance and durability.</li>
</ul>

<p><b>Additional Points to Consider:</b></p>
<ul>
    <li><b>Channel Islands Branding:</b> The brand is known for its commitment to quality and innovation, reflected in its clean and professional designs.</li>
    <li><b>Connection to Surf Culture:</b> Channel Islands is deeply connected to the global surf community, and their designs often reflect the feedback and demands of top surfers.</li>
    <li><b>Evolution:</b> Channel Islands has continuously evolved, adapting to changes in surfboard design and surfing styles. They remain at the forefront of surfboard innovation and performance.</li>
</ul>

<p><b>Where to Find More Information:</b></p>
<ul>
    <li><a href="https://cisurfboards.com/" target="_blank" rel="noopener noreferrer">Channel Islands Surfboards Official Website</a></li>
    <li>Surfboard Reviews: Websites and magazines that review surfboards often have information on Channel Islands boards.</li>
</ul>
`;

