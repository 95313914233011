
//This is GuidedSearch.js
import React, { useState } from 'react';
import { Container, Row, Col, Form, Select, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const GuidedSearch = () => {
  const navigate = useNavigate();

  const [skillLevel, setSkillLevel] = useState('');
  const [waveSize, setWaveSize] = useState('');
  const [brand, setBrand] = useState('');
  

  const handleNext = () => {
    // Construct the query parameters based on selected values
    const queryParams = new URLSearchParams();
    queryParams.append('skillLevel', skillLevel);
    queryParams.append('size', waveSize);
    if (brand) {
      queryParams.append('brand', brand);
    }

    const queryString = queryParams.toString();
    const searchUrl = `/search?${queryString}`;
    navigate(searchUrl);
  };

  return (
    <Container className="vh-100">
      <Helmet>
        <title>Guided Surfboard Search</title>
        <meta name="description" content="Find your perfect surfboard based on your skill level, wave size, and preferred brand using our guided Surfboard Search" />
        <meta name="keywords" content="surfboard finder, surfboard guide, find the right surfboard, surfboard recommendations, beginner surfboards, intermediate surfboards, advanced surfboards, wave size guide, surfboard brands, surfboard selection, surfing tips" />
      </Helmet>
      <Row className="">
        <Col sm={10}>
          <h1 className="text-center mb-4">The Surfboard Guided Search</h1>

          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group controlId="skillLevel">
                  <Form.Label>Skill Level:</Form.Label>
                  <Form.Select
                    value={skillLevel}
                    onChange={(e) => setSkillLevel(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="beginner">Beginner</option>
                    <option value="lowIntermediate">Low Intermediate</option>
                    <option value="advancedIntermediate">Advanced Intermediate</option>
                    <option value="advanced">Advanced</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <p>
                  <b>Beginner:</b> Learning to stand up and maintain balance on the wave.<br />
                  <b>Low Intermediate:</b> Able to stand and start moving along the wave face.<br />
                  <b>Advanced Intermediate:</b> Confidently maneuvering up and down the wave, performing basic cutbacks.<br />
                  <b>Advanced:</b> Competent in a wide range of maneuvers, including powerful turns, tube riding, aerials, and adapting to various wave conditions.<br />
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="waveSize">
                  <Form.Label>Size of Wave:</Form.Label>
                  <Form.Select value={waveSize} onChange={(e) => setWaveSize(e.target.value)}>
                    <option value="">Select</option>
                    <option value="knee">Knee</option>
                    <option value="waist">Waist</option>
                    <option value="shoulder">Shoulder</option>
                    <option value="overhead">Overhead</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <p>
                  We use size for waves based on an <strong>average human size</strong> instead of the infamous feet measure.
                  To clarify, we are talking in normal feet and not Hawaiian (or West Coast Australia) feet.
                  We also measure from the <strong>front of the wave</strong>, because we can't usually see the surfer from behind this one... <br />
                  <ul>
                    <li><strong>Knee High:</strong> 0 to 2 feet</li>
                    <li><strong>Waist High:</strong> 2 to 4 feet</li>
                    <li><strong>Shoulder High:</strong> 4 to 6 feet</li>
                    <li><strong>Overhead:</strong> 6+ feet</li>
                  </ul>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="brand">
                  <Form.Label>Preferred Brand:</Form.Label>
                  <Form.Select value={brand} onChange={(e) => setBrand(e.target.value)}>
                    <option value="All">All</option>
                    <option value="Channel Island">Channel Island</option>
                    <option value="Firewire">Firewire</option>
                    <option value="JS Industries">JS Industries</option>
                    <option value="Haydenshape">Haydenshape</option>
                    <option value="Lost">Lost</option>
                    <option value="Pyzel">Pyzel</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Button variant="dark" onClick={handleNext}>
              Find My Magic Surfboard
            </Button>
          </Form>
        </Col>
        <Col>

        </Col>
      </Row>
    </Container>
  );
};

export default GuidedSearch;
