import React, { createContext, useContext, useState } from 'react';

const CompareSurfboardsContext = createContext();

export const CompareSurfboardsProvider = ({ children }) => {
  const [comparedSurfboards, setComparedSurfboards] = useState([]);

  const addSurfboardToCompare = (surfboard) => {
    setComparedSurfboards((prevSurfboards) => {
      console.log("Current surfboards:", prevSurfboards);
      if (!prevSurfboards.some(s => s.id === surfboard.id)) {
        const updatedSurfboards = [...prevSurfboards, surfboard];
        console.log("Adding surfboard:", surfboard, "Updated list:", updatedSurfboards);
        return updatedSurfboards;
      }
      return prevSurfboards;
    });
  };

  const removeSurfboardFromCompare = (surfboardId) => {
    setComparedSurfboards((prevSurfboards) => {
      const updatedSurfboards = prevSurfboards.filter(s => s.id !== surfboardId);
      console.log("Removing surfboard ID:", surfboardId, "Updated list:", updatedSurfboards);
      return updatedSurfboards;
    });
  };

  return (
    <CompareSurfboardsContext.Provider value={{ comparedSurfboards, addSurfboardToCompare, removeSurfboardFromCompare }}>
      {children}
    </CompareSurfboardsContext.Provider>
  );
};

export const useCompareSurfboards = () => {
  const context = useContext(CompareSurfboardsContext);
  if (context === undefined) {
    throw new Error('useCompareSurfboards must be used within a CompareSurfboardsProvider!');
  }
  return context;
};

