// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <Container fluid className="bg-light mt-5">
      <Row>
        <Col className="text-center py-3">
          2024 -  {new Date().getFullYear()} The Ultimate Surfboards Comparator©
        </Col>
        <Col className="text-center py-3">
          <Link to="/main/disclaimer">
            Disclaimer
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="text-center py-3"><Link to="search/Firewire">See all Firewire surfboards and compare them</Link> </Col>
        <Col className="text-center py-3"><Link to="search/JS%20Industries">See all JS Industries surfboards and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="search/Channel%20Island">See all Channel Island surfboards and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="search/Haydenshapes">See all Haydenshapes surfboards and compare them</Link></Col>
      </Row>
      <Row>
        <Col className="text-center py-3"><Link to="search/knee">See all surfboards for knee-high waves and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="search/waist">See all surfboards for waist-high waves and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="search/shoulder">See all surfboards for shoulder-high waves and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="search/overhead">See all surfboards for overhead-high waves and compare them</Link></Col>
      </Row>
    </Container>
  );
}

export default Footer;
