import React, { useState, useEffect, useMemo } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase-config';
import { Card, Container, Row, Col, Collapse } from 'react-bootstrap';
import { useCompareSurfboards } from '../contexts/CompareSurfboardsContext';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const sizeRanges = {
    all: { min: 0, max: Infinity },
    knee: { min: 0, max: 2 },
    waist: { min: 2, max: 4 },
    shoulder: { min: 4, max: 6 },
    overhead: { min: 6, max: Infinity },
};

const updateMeta = (name, content) => {
    let meta = document.querySelector(`meta[name="${name}"]`);
    if (!meta) {
        meta = document.createElement('meta');
        meta.name = name;
        document.head.appendChild(meta);
    }
    meta.content = content;
};

const SearchSurfboards = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const urlBrand = searchParams.get('brand');
    const urlSize = searchParams.get('size');

    const decodedUrlBrand = urlBrand ? decodeURIComponent(urlBrand.replace(/-/g, ' ')) : null;

    const initialFilters = useMemo(() => ({
        brand: decodedUrlBrand || 'All Brands',
        size: urlSize || 'all',
    }), [decodedUrlBrand, urlSize]);

    const [filters, setFilters] = useState(initialFilters);
    const [searchResults, setSearchResults] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const { comparedSurfboards, addSurfboardToCompare, removeSurfboardFromCompare } = useCompareSurfboards();
    const brands = ["All Brands", "Channel Island", "JS Industries", "Firewire", "Haydenshape","Lost","Pyzel"];
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                let queryRef = collection(db, 'surfboards');

                if (filters.brand !== "All Brands") {
                    queryRef = query(queryRef, where('brandName', '==', filters.brand));
                }

                const querySnapshot = await getDocs(queryRef);
                let results = querySnapshot.docs.map(doc => ({ ...doc.data(), key: doc.id }));

                if (filters.size !== 'all') {
                    results = results.filter(({ waveSizeRange }) =>
                        waveSizeRange &&
                        waveSizeRange.min <= sizeRanges[filters.size].max &&
                        waveSizeRange.max >= sizeRanges[filters.size].min
                    );
                }

                setSearchResults(results);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [filters]);

    useEffect(() => {
        const decodedUrlBrand = urlBrand ? decodeURIComponent(urlBrand.replace(/-/g, ' ')) : null;
        setFilters({
            brand: decodedUrlBrand || 'All Brands',
            size: urlSize || 'all',
        });
    }, [urlBrand, urlSize]);

    useEffect(() => {
        const { brand, size } = filters;
        const sizeLabel = size.charAt(0).toUpperCase() + size.slice(1).replace(/-/g, ' ');
        const title = `Surfboards - Brand: ${brand}, Size: ${sizeLabel}`;
        document.title = title;

        const surfboardNames = searchResults.map(surfboard => surfboard.surfboardName).join(', ');
        updateMeta('description', `Find and compare surfboards like ${surfboardNames} for ${brand} brand and ${sizeLabel} wave sizes.`);
        updateMeta('keywords', `${surfboardNames}, ${brand} surfboards, ${sizeLabel} wave sizes, surfboard comparison`);

        const h1 = `Surfboards for ${brand} and ${sizeLabel} Waves`;

        const h1Element = document.getElementById('dynamicH1');
        if (h1Element) {
            h1Element.textContent = h1;
        }
    }, [filters, searchResults]);

    const handleFilterChange = (field, value) => {
        setFilters(prevFilters => {
            const newFilters = { ...prevFilters, [field]: value };

            const params = new URLSearchParams();

            if (newFilters.brand !== 'All Brands') {
                const encodedBrand = encodeURIComponent(newFilters.brand.replace(/ /g, '-'));
                params.append('brand', encodedBrand);
            }
            if (newFilters.size !== 'all') {
                params.append('size', newFilters.size);
            }

            setSearchParams(params);

            return newFilters;
        });
    };

    return (
        <Container>
            <h1 id="dynamicH1">Compare Surfboards</h1>
            <Row>
                <Col>
                    <button className="mb-3" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        {isFilterOpen ? 'Hide Filters' : 'Show Filters'}
                    </button>
                    <Collapse in={isFilterOpen}>
                        <div>
                            <select
                                value={filters.brand}
                                onChange={(e) => handleFilterChange('brand', e.target.value)}
                            >
                                {brands.map(brand => (
                                    <option key={brand} value={brand}>{brand}</option>
                                ))}
                            </select>
                            <select
                                value={filters.size}
                                onChange={(e) => handleFilterChange('size', e.target.value)}
                            >
                                {Object.keys(sizeRanges).map(sizeKey => (
                                    <option key={sizeKey} value={sizeKey}>{sizeKey}</option>
                                ))}
                            </select>
                        </div>
                    </Collapse>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={4} className="g-4 mt-3">
                {searchResults.map((surfboard, index) => (
                    <Col key={index}>
                        <Link to={`/surfboard/${surfboard.key}`}>
                            <Card className="h-100 custom-card">
                                <Card.Img variant="top" src={surfboard.imageUrls?.[0] || "/img/HPS.jfif"} style={{ objectFit: 'contain', width: '100%', height: '300px' }} />
                                <Card.Body>
                                    <Card.Title>{surfboard.surfboardName || 'Unknown name'}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                        <input
                            type="checkbox"
                            checked={comparedSurfboards.some(s => s.id === surfboard.key)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    addSurfboardToCompare({ id: surfboard.key, ...surfboard });
                                } else {
                                    removeSurfboardFromCompare(surfboard.key);
                                }
                            }}
                        /> Compare
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default SearchSurfboards;